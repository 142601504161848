const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6951337",
    space_js: "//xfzpdb.aimovetech.com/production/mhgp-e/static/qz/openjs/z/i.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951338",
    space_js: "//xfzpdb.aimovetech.com/common/n/production/ihq/source/f/resource/ra_ak.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951339",
    space_js: "//xfzpdb.aimovetech.com/source/oji_rg_sb_b/resource/r.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951340",
    space_js: "//xfzpdb.aimovetech.com/production/g-ba/common/jy-kt/production/xh.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native2,
  Home_banner: Native3,
  Detail_inter: Inter,
  Detail_native: Native2,
  Detail_banner: Native3,
  List_native: Native2,
  List_banner: Native3,
  Result_inter: Inter,
  Result_native: Native2,
  Result_banner: Native3
};
